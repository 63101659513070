import { useCallback } from "react";
import { useToggleState } from "./useToggleState";

export const useCheckBoxState = (initialState = false) => {
  const { isOpen: checked, setIsOpen: setChecked } = useToggleState(initialState);

  const onCheckChange = useCallback(
    (e: React.ChangeEvent<HTMLElement>, checked: boolean) => {
      e.stopPropagation();
      setChecked(checked);
    },
    [setChecked]
  );

  return {
    checked,
    setChecked,
    onCheckChange
  };
};
