import React, { FC, lazy, Suspense, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { IncErrorIcon, IncInfoIcon } from "../../icons";
import { IncSelectOption, IncSlimSelect } from "../Select";
import IncModal from "../Modal/Modal";
import IncButton from "../Button/Button";
import { getInceptionTheme } from "../../themes/ThemeProvider";
import { IncSpin } from "../antd-wrapper";
import { IncToolTip } from "../antd-components";
import { useClipboard, useNotifications } from "../../hooks";
import IncEditorProps from "./types";

const AceEditor = lazy(() => import("./AceEditor"));

const modes: IncSelectOption[] = [
  {
    label: "text",
    value: "text"
  },
  {
    label: "xml",
    value: "xml"
  },
  {
    label: "yaml",
    value: "yaml"
  },
  {
    label: "json",
    value: "json"
  }
];

const IncEditor: FC<IncEditorProps> = React.forwardRef((props, ref) => {
  const {
    label,
    helpText,
    helpTextId,
    showModes,
    sample,
    setOptions = {},
    required = false,
    hasError = false,
    errorText,
    disableCopy = false,
    value = ""
  } = props;

  const { notifyError, notifySuccess } = useNotifications();

  const [mode, setMode] = useState(modes.find(x => x.value === props.mode) || modes[0]);
  const [showSample, setShowSample] = useState(false);

  //defaulting font-family to use 'monospace' throughout the app
  setOptions.fontFamily = "monospace";

  const errComponent =
    errorText && hasError ? (
      <IncToolTip
        placement="top-start"
        showArrow
        titleText={errorText}
        variant="error"
      >
        <IncErrorIcon
          style={{
            color: getInceptionTheme().inceptionColors.palette.R400,
            marginLeft: !label ? 8 : 0
          }}
        />
      </IncToolTip>
    ) : (
      <></>
    );

  const { addToClipboard } = useClipboard();
  const copyToClipboard = useCallback(async () => {
    try {
      await addToClipboard(value);
      notifySuccess("Content copied to clipboard successfully");
    } catch (err) {
      console.log("Error copying content to clipboard", err);
      notifyError("Error copying content to clipboard");
    }
  }, [addToClipboard, notifyError, notifySuccess, value]);

  return (
    <div className="inc-editor-container inc-flex-column">
      {Boolean(label || sample || showModes) && (
        <div className="inc-flex-row inc-flex-space-contents marginBt6">
          {label && (
            <div className="inc-label-common">
              {label}
              {required && <span className="inc-span-required-star">*</span>}
              {(helpTextId || helpText) && (
                <IncToolTip
                  placement="top-start"
                  showArrow
                  titleId={helpTextId}
                  titleText={helpText}
                >
                  <IncInfoIcon />
                </IncToolTip>
              )}
              {errComponent}
            </div>
          )}
          <div className="inc-flex-row">
            {sample && (
              <IncButton
                className="marginRt6 example-button"
                color="link"
                onClick={() => setShowSample(true)}
              >
                <FormattedMessage id="common.words.sample" />
              </IncButton>
            )}
            {showModes && (
              <IncSlimSelect
                autoSort={false}
                label={"Mode: "}
                onChange={op => setMode(op as IncSelectOption)}
                options={modes}
                value={mode}
              />
            )}
          </div>
        </div>
      )}
      <Suspense
        fallback={
          <IncSpin
            size="default"
            spinning
          />
        }
      >
        <div className={`editor-container ${hasError ? "inc-text-error" : ""}`}>
          {!disableCopy && (
            <IncButton
              className="editor-copy-to-clipboard-button"
              color="secondary"
              iconName="clipboard"
              label="Copy"
              onClick={copyToClipboard}
              size="small"
            />
          )}
          <AceEditor
            {...props}
            mode={mode?.value || "text"}
            ref={ref}
            setOptions={setOptions}
          />
        </div>
      </Suspense>
      <IncModal
        onClose={() => setShowSample(false)}
        show={showSample}
        titleId="common.words.sample"
      >
        <AceEditor
          {...props}
          mode={mode?.value || "text"}
          readOnly={true}
          ref={ref}
          setOptions={setOptions}
          value={sample}
        />
        <div className="marginBt24" />
      </IncModal>
    </div>
  );
});

export default IncEditor;
