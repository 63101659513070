import axios from "axios";
import { cloneDeep } from "lodash";
import appConfig from "../../../appConfig";
import { configApiService } from "../../services/api";
import { ConfigMetaModel, ConfigurationModel } from "../../services/api/configuration/types/configuration";
import { User } from "../../core";
import DashboardModel, { DashboardModelMeta } from "../models/DashboardModel";
import dashboardApiService from "../../services/api/Dashboard/DashboardApiService";
import {
  CreateOrUpdateDashboardAPIResponse,
  DashboardListItem,
  DashboardMeta,
  GetDashboardResult,
  GrafanaDashboardModel
} from "./types";
import grafanaDashboardApi from "./GrafanaDashboardAPI";

interface Result<T> {
  data: T;
  error: boolean;
  message: string;
}

const USECASE_TAG_KEY = "relatedUseCases";

class DashboardApi {
  private DB_ID_PREFIX = "inc_";
  private DELETE_DASHBOARD_PATH = "delete";
  private DASHBOARD_CONFIG_TYPE = "c_dashboard_config";

  private dashboardAxiosConfig = axios.create({
    baseURL: appConfig.dashboardUrl
  });

  private storeDashboardsInGrafana = false;

  async getDashboardList(includeDbModel?: boolean): Promise<Result<DashboardListItem[]>> {
    let statusText = "";
    const dashboardListItems: DashboardListItem[] = [];

    try {
      const dashboardResult = await dashboardApiService.getDashboards();

      if (dashboardResult.data) {
        const items = this.configMetaModelToApptuitListItem(dashboardResult.data, includeDbModel);
        dashboardListItems.push(...items);
      }
    } catch (err) {
      statusText = err.message || "Failed to fetch dashboard list";
    }

    let data: DashboardListItem[] = undefined,
      error = false,
      message = "";

    if (dashboardListItems) {
      data = dashboardListItems;
    } else {
      error = true;
      message = statusText;
    }

    return {
      data,
      error,
      message
    };
  }

  async getDashboard(dashboardId: string, shareId?: string): Promise<Result<DashboardModel>> {
    const isGrafanaDashboard = this.storeDashboardsInGrafana;
    const getDashboardPromise = isGrafanaDashboard
      ? grafanaDashboardApi.getDashboard(dashboardId)
      : dashboardApiService.getDashboard(dashboardId, shareId);

    let response: Result<GetDashboardResult>;
    try {
      response = await getDashboardPromise;
      if (response.error) {
        response = await grafanaDashboardApi.getDashboard(dashboardId);
      }
    } catch (e) {
      response.error = true;
      response.message = e.message;
    }

    const newResponse: Result<DashboardModel> = {
      data: null,
      error: false,
      message: ""
    };

    if (!response.error && response.data) {
      const { dashboard, meta } = response.data;

      const incMeta: Partial<DashboardModelMeta> = {
        ...dashboard.meta,
        createdAt: meta.created,
        createdBy: meta.createdBy,
        updatedAt: meta.updated,
        updatedBy: meta.updatedBy
      };

      const dbData: DashboardModel = {
        ...dashboard,
        meta: incMeta
      };
      newResponse.data = dbData;
    } else {
      newResponse.error = response.error;
      newResponse.message = response.message;
    }
    return newResponse;
  }

  createDashboard(dbData: DashboardModel, userInfo: User) {
    return this.addOrUpdateDashboard(dbData, userInfo, false);
  }

  updateDashboard(dbData: DashboardModel, userInfo: User) {
    return this.addOrUpdateDashboard(dbData, userInfo, true);
  }

  async deleteDashboard(dashboardId: string, isMigrated?: boolean): Promise<Result<string>> {
    const isGrafanaDashboard = this.storeDashboardsInGrafana || !isMigrated;

    const uid = this.getGrafanaDashboardUid(dashboardId);
    const url = `${this.DELETE_DASHBOARD_PATH}/${uid}`;

    let data: string = undefined,
      error = false,
      message = "";

    try {
      if (isGrafanaDashboard) {
        await this.dashboardAxiosConfig.delete(url);
      } else {
        const { error: rError, message: errMsg } = await configApiService.deleteConfig(
          this.DASHBOARD_CONFIG_TYPE,
          dashboardId
        );
        error = rError;
        data = rError ? null : "success";
        message = rError ? errMsg : "";
      }
    } catch (err) {
      error = true;
      message = err.message;
    }

    return {
      data,
      error,
      message
    };
  }

  deleteDashboardWidget(widgetId: string): Promise<Result<string>> {
    return grafanaDashboardApi.deleteDashboardWidget(widgetId);
  }

  async addDashboardToUseCases(dashboardId: string, useCaseIds: string[], overWrite = false): Promise<Result<string>> {
    const result: Result<string> = {
      data: "",
      error: false,
      message: ""
    };

    try {
      const {
        data: dbConfig,
        error,
        message
      } = await configApiService.getConfig(dashboardId, this.DASHBOARD_CONFIG_TYPE);

      if (error) {
        result.error = true;
        result.message = message;
      } else {
        this.addUseCaseIdsToTags(dbConfig.tags, useCaseIds, overWrite);

        const { error: updateError, message: updateMessage } = await configApiService.updateConfig(dbConfig);

        if (updateError) {
          result.error = true;
          result.message = updateMessage;
        } else {
          result.data = "success";
        }
      }
    } catch (err) {
      result.error = true;
      result.message = err.message;
    }

    return result;
  }

  async deleteDashboardFromUseCases(dashboardId: string, useCaseIds: string[]): Promise<Result<string>> {
    const result: Result<string> = {
      data: "",
      error: false,
      message: ""
    };

    try {
      const {
        data: dbConfig,
        error,
        message
      } = await configApiService.getConfig(dashboardId, this.DASHBOARD_CONFIG_TYPE);

      if (error) {
        result.error = true;
        result.message = message;
      } else {
        this.deleteUseCaseIdsFromTags(dbConfig.tags, useCaseIds);

        const { error: updateError, message: updateMessage } = await configApiService.updateConfig(dbConfig);

        if (updateError) {
          result.error = true;
          result.message = updateMessage;
        } else {
          result.data = "success";
        }
      }
    } catch (err) {
      result.error = true;
      result.message = err.message;
    }

    return result;
  }

  canIncludeDashboardForUseCaseId(dashboard: DashboardModel, useCaseId: string, includeIfNoUseCases = false): boolean {
    const useCaseIds = this.getUseCaseIdsForDashboardTags(dashboard.meta?.tags || []);
    return useCaseIds.length ? useCaseIds.includes(useCaseId) : includeIfNoUseCases;
  }

  getUseCaseIdsForDashboardTags(tags: string[]): string[] {
    const useCaseIdsTagIdx = tags.findIndex(tag => tag.startsWith(USECASE_TAG_KEY));
    if (useCaseIdsTagIdx !== -1) {
      const [, useCaseIdsStr] = tags[useCaseIdsTagIdx].split("=");
      return useCaseIdsStr.split(",");
    }
    return [];
  }

  addUseCaseIdsToTags(tags: string[], useCaseIds: string[], overWrite = false) {
    const useCaseIdsTagIdx = tags.findIndex(tag => tag.startsWith(USECASE_TAG_KEY));
    const useCaseIdStr = useCaseIds.join(",");

    if (overWrite) {
      if (useCaseIdsTagIdx === -1 && useCaseIdStr) {
        tags.push(`${USECASE_TAG_KEY}=${useCaseIdStr}`);
      } else {
        if (useCaseIdStr) {
          tags[useCaseIdsTagIdx] = `${USECASE_TAG_KEY}=${useCaseIdStr}`;
        } else {
          tags.splice(useCaseIdsTagIdx, 1);
        }
      }
    } else {
      if (useCaseIdStr) {
        if (useCaseIdsTagIdx === -1) {
          tags.push(`${USECASE_TAG_KEY}=${useCaseIdStr}`);
        } else {
          const tagValue = tags[useCaseIdsTagIdx];
          tags[useCaseIdsTagIdx] += `${tagValue},${useCaseIdStr}`;
        }
      }
    }

    return tags;
  }

  deleteUseCaseIdsFromTags(tags: string[], useCaseIds: string[]) {
    const useCaseIdsTagIdx = tags.findIndex(tag => tag.startsWith(USECASE_TAG_KEY));
    if (useCaseIdsTagIdx !== -1) {
      const dbUseCaseIds = this.getUseCaseIdsForDashboardTags(tags);
      const newUseCaseIds = dbUseCaseIds.filter(id => !useCaseIds.includes(id));
      if (!newUseCaseIds.length) {
        tags.splice(useCaseIdsTagIdx, 1);
      } else {
        tags[useCaseIdsTagIdx] = `${USECASE_TAG_KEY}=${newUseCaseIds.join(",")}`;
      }
    }

    return tags;
  }

  private configMetaModelToApptuitListItem(configMetaModels: ConfigMetaModel[], includeDbModel?: boolean) {
    const items: DashboardListItem[] = [];
    configMetaModels.forEach(configMeta => {
      const { id, name, version, payload, tags } = configMeta;
      const { dashboardMeta } = payload;
      let dashboardModel = null;
      if (includeDbModel) {
        dashboardModel = JSON.parse(payload.dashboardJson.stringVal) as GrafanaDashboardModel;
      }
      const { created, createdBy, updated, updatedBy } = JSON.parse(dashboardMeta.stringVal) as DashboardMeta;

      items.push({
        id,
        name,
        created,
        createdBy,
        tags,
        apptuitDbId: null,
        version,
        updated,
        updatedBy,
        isMigrated: true,
        dashboardModel: includeDbModel ? dashboardModel.inceptionJson : null
      });
    });
    return items;
  }

  private getSaveConfigModel(data: DashboardModel, userInfo: User, isUpdate = false): ConfigurationModel {
    const { id, name: title, version } = data;

    const { email } = userInfo;

    const dbData = cloneDeep(data);
    dbData.version = isUpdate ? dbData.version + 1 : version;

    const tags = dbData.meta?.tags || [];

    const dashboardJson = {
      uid: id,
      title,
      version,
      inceptionJson: dbData,
      tags
    };

    const nowIso = new Date().toISOString();

    const created = isUpdate ? data.meta?.createdAt || nowIso : nowIso;
    const createdBy = isUpdate ? data.meta?.createdBy || email : email;
    const updated = nowIso;
    const updatedBy = email;

    const dashboardMeta: DashboardMeta = {
      created,
      createdBy,
      updated,
      updatedBy
    };

    return {
      id,
      createdTime: new Date().valueOf(),
      data: {
        dashboardJson: JSON.stringify(dashboardJson),
        dashboardMeta: JSON.stringify(dashboardMeta)
      },
      deleted: false,
      enabled: true,
      name: title,
      type: this.DASHBOARD_CONFIG_TYPE,
      tags,
      version: dbData.version
    };
  }

  private async addOrUpdateDashboard(
    dbData: DashboardModel,
    userInfo: User,
    isUpdate = false
  ): Promise<Result<CreateOrUpdateDashboardAPIResponse>> {
    const shouldTriggerGrafanaDbUpdate = this.storeDashboardsInGrafana;

    if (shouldTriggerGrafanaDbUpdate) {
      const { data, error, message } = await grafanaDashboardApi.createOrUpdateDashboard(dbData, isUpdate);
      return {
        data: {
          ...(data || {
            id: "",
            version: -1
          })
        },
        error,
        message
      };
    } else {
      const saveModel = this.getSaveConfigModel(dbData, userInfo, isUpdate);
      const savePromise = isUpdate ? configApiService.updateConfig(saveModel) : configApiService.addConfig(saveModel);
      const { error, message } = await savePromise;
      return {
        data: {
          id: saveModel.id,
          version: saveModel.version
        },
        error,
        message
      };
    }
  }

  // Util method to get the DB id as stored by apptuit backend.
  // We do not want the actual inception dashboard's ID to have inc_ prefix
  private getGrafanaDashboardUid(id: string) {
    return this.DB_ID_PREFIX + id;
  }
}

const dashboardApi = new DashboardApi();
export default dashboardApi;
