import { EntityPropertyValue, PrimKind } from "../../../../core";
import { DrilldownTypes } from "../../triage-v2";
import { BizDataQuery } from "./biz-data-query";
import { UserServiceFieldSliceSet, UserServiceFilterList } from ".";

export enum ToolType {
  DIMENSION_ANALYSIS = "DIMENSION_ANALYSIS",
  CORRELATED_EVENT = "CORRELATED_EVENT",
  GOOGLE_SEARCH = "GOOGLE_SEARCH"
}

export interface Workflow {
  name: string;
  description: string;
  id: string;
  icon: string;
  labels: Record<string, string>;
  workflowDef: WorkflowDef;
}

export interface WorkflowList {
  workflowList: Workflow[];
}

export interface WorkflowDef {
  causeGraphWorkFlow?: CauseGraphWorkFlow;
  toolWorkFlow?: ToolWorkFlow;
}

export interface ToolWorkFlow {}

export interface CauseGraphWorkFlow {
  causeGraph: DrilldownTypes.CauseGraph;
}

export interface ToolSpec {
  dimensionalAnalysisToolSpec?: DimensionalAnalysisToolSpec;
  correlatedEventToolSpec?: CorrelatedEventToolSpec;
  googleSearchToolSpec?: GoogleSearchToolSpec;
}

export interface DimensionalAnalysisToolSpec {
  querySource?: string;
  query: BizDataQuery;

  // one of
  dimensions: UserServiceFieldSliceSet;
  dimensionList?: {
    values: string[];
  };

  paramValues: Record<string, EntityPropertyValue>;
}

export interface CorrelatedEventToolSpec {
  querySource: string;
  filters: UserServiceFilterList;
  paramValues: Record<string, EntityPropertyValue>;
}

export interface GoogleSearchToolSpec {
  querySource: string;
  query: string;
  keywords: string[];
  paramValues: Record<string, EntityPropertyValue>;
}

export interface ToolMetaDataList {
  tools: ToolMetaData[];
}

export interface ToolMetaData {
  name: string;
  description: string;
  paramDescription: string;
  icon: string;
  svgIcon: string;
  svgIconFilePath: string;
  params: ToolParam[];
  type: ToolType;
}

export interface ToolParam {
  param: string;
  primKind: PrimKind;
  description: string;
  paramDisplayName: string;
}
