import { uniq } from "lodash";
import { PREFIX_SUMMARY } from "../services/api/traces/constants";
import { IncStructure, IncStructureTag } from "../services/api/traces/types";
import { ValueType } from "../core";
import { ENTITY, eventFieldUtils, LIST_ENTITY } from "../utils";

type Tag = {
  key: string;
  value: any;
  type: any;
};

const timeInMicrosKeys = ["spentTime", "duration", "cpuTime"];
const timeToDate = ["startTime", "endTime"];

export interface IncTraceStructureModel extends TraceStructureModel {}

class TraceStructureModel {
  structureKeysMap: Map<string, IncStructureTag> = new Map();
  private readonly entityTypes = [ENTITY, LIST_ENTITY];

  getStructureKeysMap() {
    return this.structureKeysMap;
  }

  initializeStructureKeyMaps(structureData: IncStructure): void {
    const { structure: { fields = [], globalFields = [], summary = [] } = {} } = structureData;
    this.structureKeysMap = new Map();
    for (const field of fields) {
      const { tagKey } = field;

      this.structureKeysMap.set(tagKey, {
        ...field,
        fieldType: "field",
        tagWithPrefix: eventFieldUtils.addFieldsPrefix(tagKey)
      });
    }
    for (const tag of summary) {
      const { tagKey } = tag;

      this.structureKeysMap.set(tagKey, {
        ...tag,
        fieldType: "summary",
        tagWithPrefix: PREFIX_SUMMARY + tagKey
      });
    }
    for (const tag of globalFields) {
      const { tagKey } = tag;

      this.structureKeysMap.set(tagKey, {
        ...tag,
        fieldType: "global",
        tagWithPrefix: tagKey
      });
    }
  }

  getEntityIds(keys: string[], item: { [key: string]: any }) {
    const entityIds: string[] = [];

    keys.forEach((key: string) => {
      if (Array.isArray(item[key])) {
        const tags = item[key];

        tags.forEach((tag: Tag) => {
          if (tag.key && this.checkIfTagIsTypeEntity(tag)) {
            entityIds.push(tag.value);
          }
        });
      } else if (item[key] && this.checkIfKeyIsTypeEntity(key)) {
        entityIds.push(item[key]);
      }
    });
    return entityIds;
  }

  getEntityIdsFromList(list: Array<{ [key: string]: any }>) {
    let entityIds: string[] = [];

    for (const item of list) {
      const keys = Object.keys(item) as string[];
      const ids = this.getEntityIds(keys, item);

      entityIds = [...entityIds, ...ids];
    }
    entityIds = entityIds.flat();
    return uniq(entityIds);
  }

  checkIfKeyIsTypeEntity(key: string) {
    const keyWithoutPrefix = eventFieldUtils.trimPrefixFields(key);
    const updatedKey = keyWithoutPrefix.split("$")[0];
    if (!this.structureKeysMap.get(updatedKey)) {
      return false;
    }
    const keyVal: IncStructureTag = this.structureKeysMap.get(updatedKey);
    return this.entityTypes.includes(keyVal.type);
  }

  checkIfKeyIsTypeList(key: string) {
    const keyWithoutPrefix = eventFieldUtils.trimPrefixFields(key);
    const updatedKey = keyWithoutPrefix.split("$")[0];
    if (!this.structureKeysMap.get(updatedKey)) {
      return false;
    }
    const keyVal: IncStructureTag = this.structureKeysMap.get(updatedKey);
    return keyVal.type === "LIST_ENTITY" || keyVal.type === "LIST_STRING";
  }

  checkIfTagIsTypeEntity(tag: Tag) {
    if ("type" in tag && tag.type !== undefined) {
      return this.entityTypes.includes(tag.type);
    }
    return this.checkIfKeyIsTypeEntity(tag.key);
  }

  // gets the type of entity from the tagKey
  // if key consists of entity type appended with a $ then this fn looks for the exact match in the structureKeyMap and returns the entityType
  getEntityTypeByKey(traceFieldName: string): string {
    const keyWithoutPrefix = eventFieldUtils.trimPrefixFields(traceFieldName);
    const updatedKey = keyWithoutPrefix.split("$")[0];
    if (!this.structureKeysMap.get(updatedKey)) {
      return "";
    }
    const resultList: IncStructureTag[] = [];
    this.structureKeysMap.forEach((val, key, map) => {
      const type = map.get(key)?.type;
      if (key === updatedKey && this.entityTypes.includes(type)) {
        resultList.push(val);
      }
    });
    if (resultList.length > 1 && keyWithoutPrefix.includes("$")) {
      const entityTypeOfKey = keyWithoutPrefix.split("$")[1];
      const keyVal = resultList.find(x => x.tagKey === updatedKey && x.entityType === entityTypeOfKey);
      return keyVal?.entityType ?? resultList[0].entityType;
    } else {
      return resultList[0]?.entityType;
    }
  }

  getKeyType = (key: string): ValueType => {
    if (this.checkIfKeyIsTypeEntity(key)) {
      return ValueType.entityId;
    }
    if (timeInMicrosKeys.includes(key)) {
      return ValueType.timeInMicros;
    }
    if (timeToDate.includes(key)) {
      return ValueType.date;
    }
    return ValueType.string;
  };

  getTagByKey(key: string) {
    const keyWithoutPrefix = eventFieldUtils.trimPrefixFields(key);
    const updatedKey = keyWithoutPrefix.split("$")[0];
    return this.structureKeysMap.get(updatedKey);
  }

  getTagsByDataTypes(dataTypes: string[]) {
    const tags: IncStructureTag[] = [];
    for (const tag of this.structureKeysMap.values()) {
      if (dataTypes.includes(tag.type)) {
        tags.push(tag);
      }
    }
    return tags;
  }
}

export default TraceStructureModel;
