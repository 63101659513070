import { useCallback, useMemo } from "react";
import { FetchCallBackResult, FetchFn, InitialState, useDataFetch } from "@inception/ui/src/hooks/useDataFetch";
import { ActionCategory, OpCreationConfig, operationaliseV2ApiService } from "../../../services/api/operationalise";
import { ActionInfo } from "../../../operationalise-v2/components";

export const useFetchActionCategoryList = (opConfig?: OpCreationConfig, disableAutoFetch = false) => {
  const initialState: InitialState<ActionCategory[], string> = useMemo(
    () => ({
      data: [],
      isFetching: !disableAutoFetch,
      error: null
    }),
    [disableAutoFetch]
  );

  const fetchFn = useCallback<FetchFn<ActionCategory[], string>>(async () => {
    const result: FetchCallBackResult<ActionCategory[], string> = {
      data: [],
      error: null,
      isError: false,
      isSuccess: false
    };

    try {
      const { data, error, message } = await operationaliseV2ApiService.getActionCategoryList(opConfig);

      result.data = data?.actionCategories || [];
      result.isError = error;
      result.isSuccess = !error;
      result.error = error ? message : "";
    } catch (err) {
      result.isError = true;
      result.isSuccess = false;
      result.error = err.message;
    }

    return result;
  }, [opConfig]);

  const dataFetchResponse = useDataFetch(fetchFn, initialState, disableAutoFetch);
  const { data: actionCategories } = dataFetchResponse;

  const sourceTypeInfoByCategory = useMemo(() => {
    const sourceTypeInfo: Record<string, Record<string, ActionInfo>> = {};

    if (actionCategories) {
      actionCategories.forEach(actCat => {
        const { actionSourceTypes, actionCategoryType } = actCat;

        const entries: Record<string, ActionInfo> = {};
        actionSourceTypes.forEach(actionSourceType => {
          const { sourceTypeId } = actionSourceType;
          entries[sourceTypeId] = {
            ...actionSourceType,
            categoryId: actionCategoryType
          };
        });

        sourceTypeInfo[actionCategoryType] = entries;
      });
    }

    return sourceTypeInfo;
  }, [actionCategories]);

  return {
    ...dataFetchResponse,
    sourceTypeInfoByCategory
  };
};
