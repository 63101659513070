import { SnackbarProvider } from "notistack";
import React from "react";

export const NotificationsProvider = ({ children }: { children: React.ReactNode }) => (
  <SnackbarProvider
    anchorOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    autoHideDuration={3000}
    maxSnack={3}
  >
    {children}
  </SnackbarProvider>
);
