import { BizIdProps } from "../../operationalise";
import { UserServiceFieldSlice, UserServiceFieldSliceSet } from "./exploreTypes";
import { ImpactedWidgetList } from "./triageTypes";

export interface TriageConfigRequestPayload {
  entityTypeId: string | null;
  eventTypes: string[] | null;
  configType: TriagePathConfigType;
  cohortId?: string;
  metricsId?: string;
  compareId?: string;
  widgetId?: string;
  labels?: Record<string, string>;
}

export interface TriageConfigPayload {
  id: string;
  name: string;
  idProps: BizIdProps;

  /** @deprecated TriagePathConfigType: Used for backward compatibility */
  triageConfig: TriagePathConfigType;

  triageLinkLevelEntity: TriageLinkLevelEntity;
  triageLinkLevelEvent: TriageLinkLevelEvent;
  jsonPayload?: string; // json payload as string

  dashboardPath?: DashboardPath;
  drilldownPath?: DrilldownPath;
}

export interface DashboardPath {
  dashboardId: string;
}

export interface DrilldownPath {}

export interface TriagePathConfigResponse {
  data: TriageConfigPayload;
}

export enum TriageLinkLevelEntity {
  unset = "triage_link_entity_unset",
  bizEntityType = "bizEntityType",
  cohort = "cohort"
}

export enum TriageLinkLevelEvent {
  unset = "triage_link_event_unset",
  eventType = "eventType",
  widget = "widget",
  operationalize = "operationalize"
}

export enum TriagePathConfigType {
  unset = "triage_config_type_unset",
  drilldown = "drilldown",
  dashboard = "dashboard",
  linkedDashboard = "linked_dashboard",
  impactedWidgets = "impacted_widgets",
  triagePage = "triage_page",
  diagnosticFields = "diagnostic_fields"
}

export const TRIAGE_CONFIG_LABEL = "triageConfig";

export interface TriagePathConfigListResponse {
  triagePath: TriageConfigPayload[];
}

export interface TriageDrillDownConfig extends TriageConfigPayload {
  type: TriagePathConfigType.drilldown;
  configDTO?: Record<string, any>;
}

export interface TriageEmbeddedDashboardConfig extends TriageConfigPayload {
  type: TriagePathConfigType.dashboard;
  configDTO?: {
    dashboardId: string;
  };
}

export interface TriageLinkedDashboardConfig extends TriageConfigPayload {
  type: TriagePathConfigType.linkedDashboard;
  configDTO?: {
    dashboardId: string;
  };
}

export interface TriageImpactedWidgetConfig extends TriageConfigPayload {
  type: TriagePathConfigType.impactedWidgets;
  impactedWidgets?: ImpactedWidgetList;
  configDTO?: Record<string, any>;
}

export interface TriagePageConfig extends TriageConfigPayload {
  type: TriagePathConfigType.triagePage;
  configDTO?: TriagePageConfigPayload;
}

export interface TriageDiagnosticFieldsConfig extends TriageConfigPayload {
  type: TriagePathConfigType.diagnosticFields;
  diagnosticFields?: UserServiceFieldSliceSet;
  configDTO?: Record<string, any>;
}

export interface TriagePageConfigPayload {
  showAutoDrilldown: boolean;
  showImpactIndicators: boolean;
  keyIndicators: TriagePagePinnedIndicators;
  keyContributors: TriagePagePinnedContributors;
  fieldDrilldowns: TriagePageFieldDrillDown[];
  compareTimeline: TriagePageCompareTimeline;
  displaySummaryTimeline: boolean;
  displaySummarySparkLine: boolean;
  displayKeyContributors: boolean;
  displayIncidentTimeline: boolean;
  displayFieldDrilldown: boolean;
  displayCompareTimeLine: boolean;
  displayCauseSummary: boolean;
  displayImpactSummary: boolean;
  displayCauseSummaryAnalysis: boolean;
  displayEmbeddedDashboard: boolean;
  displayLinkedDashboard: boolean;
  dashboardNamesMap: Record<string, string>;
}

export type TriagePagePinnedIndicators = {
  impactedWidgets: string[];
  sliceTagNames: string[];
};

export type TriagePagePinnedContributors = {
  sliceTagNames: string[];
};

export type TriagePageFieldDrillDownType = "alerting" | "diagnostic";

export type TriagePageFieldDrillDown = FieldDrillDownVizConfig & {
  type: TriagePageFieldDrillDownType;
  impactedWidgetId: string;
  vizConfigs: Partial<FieldDrillDownVizConfigs>;
};

export type FieldDrillDownVizConfigs = {
  distribution: FieldDrillDownVizConfig;
  stats: FieldDrillDownVizConfig;
  treeMap: FieldDrillDownVizConfig;
  table: FieldDrillDownVizConfig;
};

export type FieldDrillDownVizConfig = {
  slice: UserServiceFieldSlice;
  title: string;
};

export type TriagePageCompareTimeline = {
  title: string;
  groupBy: string[];
  impactedWidgetId: string;
};

export type TriageConfigDTO =
  | TriageDrillDownConfig
  | TriageLinkedDashboardConfig
  | TriageEmbeddedDashboardConfig
  | TriageImpactedWidgetConfig
  | TriageDiagnosticFieldsConfig
  | TriagePageConfig;
