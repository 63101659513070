import { ISaxIconName, IncFaIconName } from "@inception/ui";
import { LabelFragment } from "../../chat";
import { UserServiceTuple, TimeObj } from "../../explore";

export interface OpSchedule {
  schedule: Schedule;
  startOffset: TimeObj;
  endOffset: TimeObj;
  labels?: OpScheduleLabels;
}

export interface OpScheduleLabels extends Record<string, string> {
  timeZone?: string;
}

export interface Schedule {
  specificScheduleConfig?: SpecificScheduleConfig;
  execTimeEpochSecs?: number;

  startTimeEpochSecs: number;
  endTimeEpochSecs: number;

  timeZone?: string; // IANA timezone
}

export interface PrimaryBizIdProps {
  bizEntityTypeId?: string;
  eventTypes?: UserServiceList;
}

export interface SecondaryBizIdProps {
  widgetId?: string;
  cohortId?: string;
  labels?: Record<string, string>;
}
export interface BizIdProps {
  primary: PrimaryBizIdProps;
  secondary: SecondaryBizIdProps;
}

export interface UserServiceList {
  userServiceInfo: UserServiceTuple[];
}

export interface SpecificScheduleConfig {
  minute?: ScheduleDefForUnit;
  hour?: ScheduleDefForUnit;
  dayOfMonth?: ScheduleDefForUnit;
  month?: ScheduleDefForUnit;
  dayOfWeek?: ScheduleDefForUnit;
}

export interface ScheduleDefForUnit {
  step?: number;
  range?: OpScheduleDefUnitRange;
  list?: OpScheduleDefUnitList;
}

export interface OpScheduleDefUnitList {
  list: number[];
}

export interface OpScheduleDefUnitRange {
  min: number;
  max: number;
}

export enum ActionRunMode {
  runModeUnset = "runModeUnset",
  bulk = "bulk",
  loop = "loop"
}

export interface CauseMetaData {
  causes: string[];
  causeGraphContext: string;
  actionsContext: string;
}

export enum OpType {
  // real_time, trend, outlier, multi_dim, series_change
  OP_TYPE_NA = "OP_TYPE_NA",
  REAL_TIME = "REAL_TIME",
  TREND = "TREND",
  TIME_OUTLIER = "TIME_OUTLIER",
  SERIES_OUTLIER = "SERIES_OUTLIER",
  MULTI_DIMENSION_ANALYSIS = "MULTI_DIMENSION_ANALYSIS",
  SERIES_CHANGE = "SERIES_CHANGE",
  OP_ANALYSIS = "OP_ANALYSIS",
  JOURNEY_ANALYSIS = "JOURNEY_ANALYSIS"
}

export interface WhatsNewConfig {
  doSetup: boolean;
  lookbackPeriod: TimeObj;
}

export type OpMetaDataV2 = {
  name: string;
  description: string;
  opConfigProps: Record<string, string>;
  importance: string;
  isOpportunity: boolean;
  causes: string[];
  actionsContext: string;
  labelFragment: LabelFragment[];
  icon: ISaxIconName | IncFaIconName;
};

export enum TrendType {
  TREND_TYPE_NA = "TREND_TYPE_NA",
  AUTO = "AUTO",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY"
}
