import { useCallback, useState } from "react";

export const useForceUpdate = (callback?: () => void) => {
  const [, setUpdater] = useState(0);

  return useCallback(() => {
    setUpdater(u => u + 1);
    if (callback) {
      callback();
    }
  }, [callback, setUpdater]);
};
